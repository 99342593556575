import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OBJECT_SYNC_STORAGE,
  SyncProcessorService,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, map, Observable } from 'rxjs';
// internal
import { MachineModel } from 'src/app/models/machine';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends OfflineDataService<MachineModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Machine',
      http,
      errorService,
      events,
      'machines/',
      'machine',
      [
        'created_at',
        'updated_at',
        'created_by_id',
        'customer',
        'open_damages',
        'category',
      ],
      [
        'customer_location',
        'planogram_articles',
        'planogram',
        'layout',
        'select_buttons',
      ],
    );
    this.bulkSize = 10;
    this.bulkSyncActive = true;
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  /**
   * Validiert die Offlinedaten in jedem Sync
   * @return true, wenn validiert werden soll
   */
  override get checkEachSync(): boolean {
    return this.checkAlreadyValidated();
  }

  /**
   * Update der Seriennummer der Maschine
   * - Aktualisiert die Maschinendaten lokal mit der neuen Seriennummer
   * - Reiht die Änderung der Seriennummer in der Warteschlange ein
   */
  public async updateSerialNumber(
    machineId: number,
    serialNumber: string,
  ): Promise<void> {
    if (!machineId || !serialNumber) return;
    try {
      const machine: MachineModel = await firstValueFrom(this.find(machineId));
      machine.serial_number = serialNumber;
      // Stammdaten lokal aktualisieren
      await this.saveLocalForce(machine);
      // Aktualisierung in der Warteschlange einreihen
      await firstValueFrom(
        this.save({
          id: machineId,
          serial_number: serialNumber,
        } as MachineModel),
      );
    } catch (error) {
      console.error(
        '[MachineService][updateSerialNumber]: Fehler beim Update der Seriennummer',
        error,
      );
    }
  }

  /**
   * Name des Icons für die Klasse
   * @return string - Name des Icons
   */
  public get iconName(): string {
    return 'cafe-outline';
  }

  //////////////////////////////////////////////////////////////
  //// FIND/ALL BY INDEX
  /**
   * Suche nach **einer** Maschine anhand des Attributs `remoteId` mithilfe des remoteId Index
   * @param remoteId - Remote-ID der Maschine
   * @returns Observable<MachineModel | undefined> - Die gefundene Maschine oder undefined
   */
  public findByRemoteId(
    remoteId: string,
  ): Observable<MachineModel | undefined> {
    return this.indexedDBService
      .getByIndex<StoredData<MachineModel>>(OBJECT_SYNC_STORAGE, 'remoteId', [
        this.type,
        remoteId,
      ])
      .pipe(map(data => data?.content));
  }

  /**
   * Suche nach **einer** Maschine anhand des Attributs `eancode` mithilfe des eancode Index
   * @param eancode - EAN-Code der Maschine
   * @returns Observable<MachineModel | undefined> - Die gefundene Maschine oder undefined
   */
  public findByEan(eancode: string): Observable<MachineModel | undefined> {
    return this.indexedDBService
      .getByIndex<StoredData<MachineModel>>(OBJECT_SYNC_STORAGE, 'eancode', [
        this.type,
        eancode,
      ])
      .pipe(map(data => data?.content));
  }

  /**
   * Suche nach **allen** Maschinen anhand des Attributs `customerId` mithilfe des customerId Index
   * @param customerId - ID des Kunden
   * @returns Observable<MachineModel[]> - Die gefundenen Maschinen
   */
  public allByCustomerId(customerId: number): Observable<MachineModel[]> {
    const range: IDBKeyRange = IDBKeyRange.bound(
      [this.type, customerId],
      [this.type, customerId],
    );
    return this.indexedDBService
      .getAllByIndex<StoredData<MachineModel>>(
        OBJECT_SYNC_STORAGE,
        'customerId',
        range,
      )
      .pipe(map(data => data.map(d => d.content)));
  }
}
