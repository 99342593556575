import { FULLTEXT_STORES } from 'src/assets/constants/fulltext-stores';

/**
 * Erstellt für alle benötigten Sprachen einen
 * @param db
 */
export function fullTextStoresMigration(db: IDBDatabase) {
  for (const storeName of FULLTEXT_STORES) {
    try {
      const store = db.createObjectStore(storeName, {
        autoIncrement: false,
        keyPath: ['key', 'entityType'],
      });
      store.createIndex('key', 'key', { unique: true });
      store.createIndex('entityType', 'entityType', { unique: false });
      store.createIndex('references', 'references', { unique: false });
    } catch (error) {
      console.error(`error creating ${storeName} store`, error);
    }
  }
}
