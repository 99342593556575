import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OBJECT_SYNC_STORAGE,
  SyncProcessorService,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { map, Observable } from 'rxjs';
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { MachineService } from 'src/app/providers/model-services/machines/machine.service';
import { OfflineDataService } from 'src/app/providers/offlineData.service';
import { DamageReportModel } from 'src/packages/damage-reporting/data/models/damage-report.model';

@Injectable({
  providedIn: 'root',
})
export class DamageReportOfflineDataService extends OfflineDataService<DamageReportModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    public machineService: MachineService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Reporting::DamageReport',
      http,
      errorService,
      events,
      'reporting/damage_reports/',
      'reporting_damage_report',
      ['created_at', 'updated_at', 'created_by_id', 'file_url'],
      ['damages'],
    );
  }

  /**
   * Name des Icons für die Klasse
   */
  public get iconName(): string {
    return 'warning';
  }

  /**
   * Speichert ein Schadensbericht-Objekt in der IndexedDB
   * - Setzt das `customer_id` Attribut auf die `customer_id` der Maschine
   * @param obj - Das zu speichernde Schadensbericht-Objekt
   * @param updatedAt - Das Datum, an dem das Objekt zuletzt aktualisiert wurde
   * @protected
   */
  protected override async store(
    obj: DamageReportModel,
    updatedAt: string,
  ): Promise<boolean | StoredData<DamageReportModel>> {
    obj.customer_id = obj.machine.customer_id;
    return await super.store(obj, updatedAt);
  }

  /**
   * Suche nach **allen** Schadensberichten anhand des Attributs `customer_id` mithilfe des customerId Index
   * @param customerId - ID des Kunden, zu dem die Schadensberichte gehören
   * @returns Observable<DamageReportModel[]> - Die gefundenen Schadensberichte
   */
  public allByCustomerId(customerId: number): Observable<DamageReportModel[]> {
    const range: IDBKeyRange = IDBKeyRange.bound(
      [this.type, customerId],
      [this.type, customerId],
    );
    return this.indexedDBService
      .getAllByIndex<StoredData<DamageReportModel>>(
        OBJECT_SYNC_STORAGE,
        'customerId',
        range,
      )
      .pipe(map(data => data.map(d => d.content)));
  }

  /**
   * Suche nach **allen** Schadensberichten anhand des Attributs `machine_id` mithilfe des machineId Index
   * @param machineId - ID der Maschine, zu der die Schadensberichte gehören
   * @returns Observable<DamageReportModel[]> - Die gefundenen Schadensberichte
   */
  public allByMachineId(machineId: number): Observable<DamageReportModel[]> {
    const range: IDBKeyRange = IDBKeyRange.bound(
      [this.type, machineId],
      [this.type, machineId],
    );
    return this.indexedDBService
      .getAllByIndex<StoredData<DamageReportModel>>(
        OBJECT_SYNC_STORAGE,
        'machineId',
        range,
      )
      .pipe(map(data => data.map(d => d.content)));
  }
}
