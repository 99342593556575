import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index `remoteId` zum objectStore 'objects' der IndexedDB hinzu.
 * @param transaction - Die Transaktion für die Migration
 */
export function remoteIdIndexMigration(transaction: IDBTransaction) {
  const store = transaction.objectStore(OBJECT_SYNC_STORAGE);
  store.createIndex('remoteId', ['entityType', 'content.remote_id'], {
    unique: false,
  });
}
