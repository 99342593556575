<ion-app>
  <ion-split-pane contentId="main" when="lg">
    <ion-menu #ionicMenu contentId="main" menuId="mainMenu">
      <ion-header style="padding: 4px">
        <app-logo></app-logo>
      </ion-header>
      <ion-content *ngIf="sideMenu && userHelper.getManBus()">
        <ion-item>
          <ion-label position="floating" i18n>Betriebsstätte</ion-label>
          <ion-select
            [(ngModel)]="selectedBusiness"
            [compareWith]="compareWith"
            [disabled]="userHelper.getUser()?.businesses?.length <= 1"
            (ionChange)="changedBusiness()"
            i18n-okText
            i18n-cancelText
            okText="Auswählen"
            cancelText="Abbrechen"
          >
            <ion-select-option
              *ngFor="let business of userHelper.getUser()?.businesses"
              [value]="business"
            >
              {{ business.name1 }} {{ business.name2 }} {{ business.name3 }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="{{ group.cssClass }}" *ngFor="let group of sideMenu.groups">
          <span *ngIf="group.accessGranted && !group.hidden">
            <ion-item
              class="menu-heading"
              *ngIf="group.title"
              [title]="group.title"
              expand="full"
              lines="full"
            >
              <span class="menu-heading" expand="full">
                {{ group.title }}
              </span>
            </ion-item>
            <div *ngFor="let element of group.elements" [title]="element.title">
              <ion-menu-toggle auto-hide="false">
                <ion-item
                  *ngIf="
                    element.accessGranted &&
                    (element.offlineSupported || systemHelper.isOnline) &&
                    !element.subElements &&
                    !element.hidden
                  "
                  [ngClass]="{
                    active: element.name && this.selectedPage === element.name
                  }"
                  [title]="element.title"
                  (click)="openPage(element)"
                  lines="full"
                >
                  <ion-icon
                    [name]="element.icon"
                    [color]="
                      element.name && this.selectedPage === element.name
                        ? 'primary'
                        : element.color
                    "
                  >
                  </ion-icon>
                  <ion-label [color]="element.color">
                    {{ element.title }}
                  </ion-label>
                  <mits-badge
                    *ngIf="element.badge"
                    [color]="element.badge.color"
                    [value]="element.badge.count$ | async"
                    slot="end"
                  >
                  </mits-badge>
                </ion-item>
              </ion-menu-toggle>
              <div *ngIf="element.subElements">
                <ion-item
                  *ngIf="
                    element.accessGranted &&
                    !element.hidden &&
                    (element.offlineSupported || systemHelper.isOnline)
                  "
                  [ngClass]="{
                    active: element.name && this.selectedPage === element.name
                  }"
                  [title]="element.title"
                  (click)="changeSubElementsVisible(element)"
                  lines="full"
                >
                  <ion-icon
                    [name]="element.icon"
                    [color]="
                      element.name && this.selectedPage === element.name
                        ? 'light'
                        : element.color
                    "
                  ></ion-icon>
                  <ion-label class="ion-padding" [color]="element.color">
                    {{ element.title }}
                  </ion-label>
                  <ion-icon
                    class="colorGrey"
                    *ngIf="!element.subelementsVisible"
                    name="arrow-down"
                    slot="end"
                  >
                  </ion-icon>
                  <ion-icon
                    class="colorGrey"
                    *ngIf="element.subelementsVisible"
                    name="arrow-up"
                    slot="end"
                  ></ion-icon>
                </ion-item>
                <div class="menuSubList" *ngIf="element.subelementsVisible">
                  <ng-container *ngFor="let subElement of element.subElements">
                    <ion-item
                      *ngIf="
                        subElement.accessGranted &&
                        !subElement.hidden &&
                        (element.offlineSupported || systemHelper.isOnline)
                      "
                      [ngClass]="{
                        active:
                          subElement.name && selectedPage === subElement.name
                      }"
                      [title]="subElement.title"
                      (click)="openPage(subElement)"
                      lines="full"
                    >
                      <ion-icon
                        [name]="subElement.icon"
                        [color]="subElement.color"
                      ></ion-icon>
                      <ion-label> {{ subElement.title }} </ion-label>
                      <mits-badge
                        *ngIf="subElement.badge"
                        [color]="subElement.badge.color"
                        [value]="subElement.badge.count$ | async"
                        slot="end"
                      >
                      </mits-badge>
                    </ion-item>
                  </ng-container>
                </div>
              </div>
            </div>
          </span>
        </div>
      </ion-content>
      <ion-footer class="ion-text-center">
        <ion-item
          class="animatePulseOpacity"
          *ngIf="
            (errorLogHelper.hasAny() && userHelper.isAdmin) ||
            errorLogHelper.hasWarnings() ||
            errorLogHelper.hasErrors()
          "
          [detail]="this.userHelper.isAdmin"
          [color]="
            errorLogHelper.hasErrors()
              ? 'danger'
              : errorLogHelper.hasWarnings()
              ? 'warning'
              : 'medium'
          "
          [routerLink]="this.userHelper.isAdmin ? 'statusboard' : '.'"
          (click)="errorLogHelper.reloadErrorlogs()"
          lines="full"
        >
          <ion-icon
            [name]="
              errorLogHelper.hasErrors() || errorLogHelper.hasWarnings()
                ? 'warning-outline'
                : 'information-circle-outline'
            "
            [ngClass]="{ animatePulseScale: errorLogHelper.hasErrors() }"
            size="default"
            slot="start"
          >
          </ion-icon>
          <ion-text>
            { errorLogHelper.hasErrors() || errorLogHelper.hasWarnings(), select
            , true {Einrichtung nicht abgeschlossen!} other {Informationen zur
            weiteren Einrichtung}}
          </ion-text>
        </ion-item>
        <ng-container *ngIf="workerActive">
          <ion-item lines="none">
            <ion-icon slot="start" name="search"></ion-icon>
            <ion-text>
              <strong i18n>Artikelsuche wird aufgebaut</strong>
            </ion-text>
          </ion-item>
          <ion-progress-bar
            type="indeterminate"
            color="tertiary"
          ></ion-progress-bar>
        </ng-container>
        <div class="infoItem" *ngIf="!systemHelper.isBackendOnline">
          <span i18n>Server nicht erreichbar</span>
          <p i18n>
            Die App funktioniert nur stark eingeschränkt, da der Server nicht
            erreichbar ist.<br />
            Bitte melden Sie sich sofort bei Ihrem Administrator.
          </p>
        </div>
        <div class="infoItem" *ngIf="!systemHelper.isOnline">
          <span i18n>Sie sind Offline</span>
          <p i18n>
            Einige Features können ausgeblendet sein, da diese Offline nicht
            verfügbar sind.
          </p>
        </div>
        <ion-item *ngIf="userHelper.getUser()" lines="full">
          <mits-language-picker
            *ngIf="isHomePage; else personIcon"
            slot="start"
          ></mits-language-picker>
          <ng-template #personIcon>
            <ion-icon
              *ngIf="systemHelper.currentEvent !== 'christmas'"
              slot="start"
              name="person-outline"
            ></ion-icon>
            <mits-icon
              *ngIf="systemHelper.currentEvent === 'christmas'"
              slot="start"
              name="person-christmas"
            ></mits-icon>
          </ng-template>
          <ion-text>
            <div i18n>Angemeldet als:</div>
            <strong>
              {{ userHelper.getUserString(userHelper.getUser()) }}
            </strong>
          </ion-text>
        </ion-item>
        <ion-item
          class="animatePulseOpacity colorBgDebug"
          id="dev-trigger"
          [hidden]="!systemHelper.isDeveloper"
          detail
          button
        >
          <ion-icon
            class="animatePulseScale"
            name="warning-outline"
            slot="start"
            size="default"
          ></ion-icon>
          <ion-text>Entwicklermodus aktiviert!</ion-text>
        </ion-item>
        <ion-popover
          class="devPopover"
          trigger="dev-trigger"
          triggerAction="click"
        >
          <ng-template>
            <dev-options></dev-options>
          </ng-template>
        </ion-popover>
        <ion-item
          class="animatePulseOpacity"
          *ngIf="systemHelper.isDownloadRunning && systemHelper.isOnline"
          color="warning"
        >
          <ion-icon
            class="animateRotate"
            name="sync-outline"
            color="dark"
            slot="start"
            size="default"
          ></ion-icon>
          <ion-text color="dark">
            <strong i18n>Synchronisation läuft...</strong>
            <span class="smallText" i18n
              >Währenddessen können in der App Unterbrechungen auftreten</span
            >
          </ion-text>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet
      id="main"
      [environmentInjector]="enviromentInjector"
    ></ion-router-outlet>
  </ion-split-pane>
</ion-app>
