import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index `eancode` zum objectStore 'objects' der IndexedDB hinzu.
 * @param transaction
 */
export function eancodeIndexMigration(transaction: IDBTransaction) {
  const store = transaction.objectStore(OBJECT_SYNC_STORAGE);
  store.createIndex('eancode', ['entityType', 'content.eancode'], {
    unique: false,
  });
}
