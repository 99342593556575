import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index `machineId` zum objectStore 'objects' der IndexedDB hinzu.
 * Der Index besteht aus den Feldern `entityType` und `content.machine_id`.
 * @param transaction - Die Transaktion für die Migration
 */
export function machineIdIndexMigration(transaction: IDBTransaction) {
  transaction
    .objectStore(OBJECT_SYNC_STORAGE)
    .createIndex('machineId', ['entityType', 'content.machine_id'], {
      unique: false,
    });
}
