<ng-container *ngIf="gridTemplateColumns">

<!--    <ion-item style="&#45;&#45;border-radius: 12px;" fill="solid"-->
<!--              *ngIf="currentBreakpoint === mobileLayoutBreakpoint && isOrderable">-->
<!--        <ion-label>Sortierung</ion-label>-->
<!--        <ion-select placeholder="Sortieren nach" (ionChange)="emitOrderByMobile($event)"-->
<!--                    [value]="orderedByIndicator.attribute">-->
<!--            <ng-container *ngFor="let column of columnConfigs">-->
<!--                <ion-select-option [value]="column.attribute" *ngIf="!column.notOrderable">-->
<!--                    {{ column.label ?? column.attribute }}-->
<!--                </ion-select-option>-->
<!--            </ng-container>-->
<!--        </ion-select>-->
<!--    </ion-item>-->

    <div class="table" [ngClass]="currentBreakpoint === mobileLayoutBreakpoint? 'is-mobile' : ''"
         [ngStyle]="{'grid-template-columns': gridTemplateColumns}">
        <div class="table-header">
            <ng-container *ngFor="let column of columnConfigs">
                <div class="table-cell" *ngIf="!(!!column.breakpointConfig?.[currentBreakpoint]?.hide)"
                     (click)="!column.notOrderable && emitOrderBy(column)">
                    <strong>{{ column.label ?? column.attribute }}</strong>
                    <span *ngIf="!column.notOrderable && (orderedByIndicator?.attribute === column.attribute) || (orderedByIndicator?.attribute === column.orderByParam)">
            {{ orderedByIndicator.direction === 'ASC' ? '▲' : '▼' }}
          </span>
                </div>
            </ng-container>

            <!-- Neue Spalte "Aktionen" -->
            <div class="table-cell" *ngIf="itemsDeletable">
                <strong style="float: right;">Aktionen</strong>
            </div>
        </div>

        <!-- Tabellen-Zeilen -->
        <div class="table-row"
             *ngFor="let row of data"
             (click)="itemsClickable ? itemClicked.emit(row.id) : ''"
             [ngClass]="{'clickable': itemsClickable}">
            <ng-container *ngFor="let column of columnConfigs">
                <div class="table-cell"
                     *ngIf="!(!!column.breakpointConfig?.[currentBreakpoint]?.hide)">
                    <ng-container *ngIf="currentBreakpoint !== mobileLayoutBreakpoint; else mobileLayout">
                        <div *ngIf="column.HTMLElementFactory"
                             [mitsHtmlRenderer]="column.HTMLElementFactory"
                             [value]="row[column.attribute]"></div>
                        <ion-badge *ngIf="column.specialType === 'badge'"
                                   [color]="getColumnColor(row, column)">
                            {{ column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute] }}
                        </ion-badge>
                        <span *ngIf="!column.specialType && !column.HTMLElementFactory"
                              [innerHTML]="(column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute])
                                | mitsSafeHtml">
            </span>
                    </ng-container>

                    <!-- MOBILE Layout -->
                    <ng-template #mobileLayout>
                        <div class="mobile-cell">
                            <span class="label">{{ column.label ?? column.attribute }}:</span>
                            <ion-badge *ngIf="column.specialType === 'badge'">
                                {{ column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute] }}
                            </ion-badge>
                            <span *ngIf="!column.specialType" class="value"
                                  [innerHTML]="(column.transformValue ? column.transformValue(row[column.attribute]) : row[column.attribute])
                                  | mitsSafeHtml">
              </span>
                        </div>
                    </ng-template>
                </div>
            </ng-container>

            <!-- Zelle mit Papierkorb (nur falls itemsDeletable) -->
            <div class="table-cell" *ngIf="itemsDeletable">
                <ion-icon name="trash-outline" color="danger" style="font-size: 24px; float: right;"
                          (click)="onDeleteClicked($event, row.id)"></ion-icon>
            </div>
        </div>
    </div>
</ng-container>
