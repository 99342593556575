import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index ObjectName zur Indexeddb hinzu, dessen Pfad zur property display_name
 * aller Entitäten führt.
 */
export function objectNameMigration(transaction: IDBTransaction) {
  const store = transaction.objectStore(OBJECT_SYNC_STORAGE);
  store.createIndex('objectName', ['entityType', 'content.display_name'], {
    unique: false,
  });
}
