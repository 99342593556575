import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OBJECT_SYNC_STORAGE,
  SyncProcessorService,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { map, Observable } from 'rxjs';
import { CustomerLocationModel } from 'src/app/models/customers/location';
import { ErrorService } from '../../error.service';
import { EventsService } from '../../events.service';
import { OfflineDataService } from '../../offlineData.service';

@Injectable({
  providedIn: 'root',
})
export class LocationCustomerService extends OfflineDataService<CustomerLocationModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Customers::Location',
      http,
      errorService,
      events,
      'customers/locations/',
      'location',
      ['created_at', 'updated_at'],
      [],
    );

    this.bulkSyncActive = true;
  }

  /**
   * Suche nach **allen** Kunden-Standorten anhand des Attributs `customerId` mithilfe des customerId Index
   * @param customerId - ID des Kunden
   * @returns Observable<CustomerLocationModel[]> - Die gefundenen Kunden-Standorte
   */
  public allByCustomerId(
    customerId: number,
  ): Observable<CustomerLocationModel[]> {
    const range: IDBKeyRange = IDBKeyRange.bound(
      [this.type, customerId],
      [this.type, customerId],
    );
    return this.indexedDBService
      .getAllByIndex<StoredData<CustomerLocationModel>>(
        OBJECT_SYNC_STORAGE,
        'customerId',
        range,
      )
      .pipe(map(data => data.map(d => d.content)));
  }
}
