import {OBJECT_SYNC_STORAGE} from '@vending/sync-engine-client';

/**
 * Fügt den Index `customerId` zum objectStore 'objects' der IndexedDB hinzu.
 * Der Index besteht aus den Feldern `entityType` und `content.customer_id`.
 * @param transaction - Die Transaktion für die Migration
 */
export function customerIdIndexMigration(transaction: IDBTransaction) {
  transaction
      .objectStore(OBJECT_SYNC_STORAGE)
      .createIndex(
          'customerId',
          ['entityType', 'content.customer_id'],
          {
            unique: false,
          }
      );
}
