import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index `trackable` zum objectStore 'objects' der IndexedDB hinzu.
 * Der Index besteht aus den Feldern `trackable_type` und `trackable_id`.
 * @param transaction - Die Transaktion für die Migration
 */
export function trackableIndexMigration(transaction: IDBTransaction) {
  transaction
    .objectStore(OBJECT_SYNC_STORAGE)
    .createIndex(
      'trackable',
      ['content.trackable_type', 'content.trackable_id'],
      {
        unique: false,
      },
    );
}
