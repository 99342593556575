import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Fügt den Index `inventoryRequired` zum objectStore 'objects' der IndexedDB hinzu.
 * Der Index besteht aus dem Feld `inventory_required_by_id`.
 * @param transaction - Die Transaktion für die Migration
 */
export function stockObjectInventoryIndexMigration(
  transaction: IDBTransaction,
) {
  transaction
    .objectStore(OBJECT_SYNC_STORAGE)
    .createIndex('inventoryRequired', 'content.inventory_required_by_id', {
      unique: false,
    });
}
