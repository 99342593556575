import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { inject } from '@angular/core';

/**
 * SafeHtmlPipe
 * - Sanitizes HTML
 * @see https://blog.angular-university.io/angular-innerhtml/
 */
@Pipe({
    name: 'mitsSafeHtml',
    standalone: true,
})
export class MitsSafeHtmlPipe {
    // DomSanitizer for sanitizing HTML
    private sanitizer: DomSanitizer = inject(DomSanitizer);

    transform(html?: any): SafeHtml {
        if (!html || typeof html !== 'string') html = ''
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}