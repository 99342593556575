/**
 * Erstellt den articleEan Store in der IndexedDB mit den Incices 'remoteId' 'amount' und 'articleId'
 * @param db
 */
export function articleEanStoreMigration(db: IDBDatabase) {
  const store = db.createObjectStore('articleEan', { keyPath: 'remoteId' });
  store.createIndex('remoteId', 'remoteId', { unique: true });
  store.createIndex('amount', 'amount', { unique: false });
  store.createIndex('articleId', 'articleId', { unique: false });
}
