import {Routes} from '@angular/router';
import {PendingChangesGuard} from 'src/app/guards/pending-changes.guard';

// The base route for the damage reporting domain
export const DAMAGE_REPORTING_BASE_ROUTE = 'damage-reporting';
// The routes for the damage report management
export const DAMAGE_REPORTING_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'damage-reports',
        pathMatch: 'full'
    },
    ////////////////////////// DAMAGE REPORT MANAGEMENT //////////////////////////
    /// LIST of Damage Reports
    {
        path: 'damage-reports',
        loadComponent: () =>
            import('src/packages/damage-reporting/features/damage-report-list/damage-report-list.page.component'),
    },
    /// CREATE Damage Report
    {
        path: 'damage-reports/create',
        loadComponent: () =>
            import('src/packages/damage-reporting/features/damage-report-create/damage-report-create.page.component'),
        canDeactivate: [PendingChangesGuard],
    },
    /// SHOW Damage Report and UPDATE State of Damages in the Report
    {
        path: 'damage-reports/details/:id',
        loadComponent: () =>
            import('src/packages/damage-reporting/features/damage-report-show-update/damage-report-show-update.page.component'),
        canDeactivate: [PendingChangesGuard],
    }
];
