import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { filter, Observable, pairwise } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { Identifiable } from 'src/app/models/basic';
import { IndexDataFunction } from 'src/app/providers/page-services/index-data/index-data.interface';
import { IndexDataService } from 'src/app/providers/page-services/index-data/index-data.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import { MitsHeaderModule } from '../../mits-header/mits-header.module';
import { MitsIconModule } from '../../mits-icon/mits-icon.module';
import { LoadingSpinnerComponent } from '../../mits-loading-spinner/mits-loading-spinner.component';
import { MitsPaginationComponent } from '../../mits-pagination/mits-pagination.component';
import { ScannerData } from '../../mits-scanner-button/mits-scanner-button.component';
import { MitsScannerButtonModule } from '../../mits-scanner-button/mits-scanner-button.module';
import { MitsFormObjectSelectionControlValueAccessorDirective } from './directives/mits-form-object-selection.control-value-accessor.directive';

import { ObjectSelectionDisplayPipePipe } from './pipes/mits-form-object-selection.display.pipe';

// Interface für die Funktion, die Daten bereitstellt
export type FindDataFunction<T> = (id: number) => Promise<T>;
export type ScanDataFunction<T> = (scan: string) => Promise<T>;

const TRIGGER_DATA_ATTRIBUTE = 'mits-object-selection-trigger-id';

@Component({
  selector: 'mits-form-object-selection',
  templateUrl: './mits-form-object-selection.component.html',
  styleUrls: ['./mits-form-object-selection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MitsHeaderModule,
    ObjectSelectionDisplayPipePipe,
    MitsPaginationComponent,
    LoadingSpinnerComponent,
    MitsIconModule,
    MitsScannerButtonModule,
  ],
  hostDirectives: [MitsFormObjectSelectionControlValueAccessorDirective],
  providers: [IndexDataService],
})
export class MitsFormObjectSelectionComponent<T extends Identifiable>
  implements OnChanges, AfterViewInit, OnDestroy
{
  ////////// INPUTS
  // Ausgewähltes Objekt bzw. Ausgewählte Objekte, wenn multiple=true
  @Input() value?: T | T[];
  // Zeigt an, ob die Auswahl mehrere Objekte zulässt (Standard: false)
  @Input() multiple: boolean = false;
  // Zeigt an, ob die Auswahl deaktiviert ist
  @Input() disabled = false;
  // Zeigt an, ob die Suche deaktiviert ist
  @Input() disableSearch = false;
  // Die ID des Elements auf dessen click Event das Modal geöffnet werden soll
  @Input() externalTrigger?: string | undefined;
  // Versteckt das Eingabefeld (sollte nur versteckt werden, wenn ein externalTrigger gesetzt ist)
  @Input() hideInputField?: boolean = false;
  // Der Text, der als Label für die Auswahl angezeigt wird
  @Input() label = $localize`Objekt`;
  // Zeigt an, ob die Komonente in der Formularverwendung Objekt(e) oder ID(s) des/der Objekte(s) zurückgeben soll
  @Input() returnObjectsInsteadOfIds: boolean = false;
  // Zeigt an, ob der Scanner-Button aktiviert ist
  @Input() enableScanner: boolean = false;
  // Der Text, der als Platzhalter in der Auswahl angezeigt wird
  @Input() placeholder = '';
  // Die Art des Ladens (pagination oder infinite-scroll) (Standard: infinite-scroll)
  @Input() loadingType: 'pagination' | 'infinite-scroll' = 'infinite-scroll';
  // Die Funktion, die die Daten für die Auswahl bereitstellt (wird bevorzugt vor data verwendet)
  @Input() dataFunction?: IndexDataFunction<T>;
  // Die Funktion, die ein Objekt anhand seiner ID bereitstellt (wird beim Setzen des Wertes (als ID) verwendet)
  @Input() dataFunctionFind?: FindDataFunction<T>;
  // Die Funktion, die ein Objekt anhand eines Scans bereitstellt (wird beim Scannen verwendet)
  @Input() dataFunctionScan?: ScanDataFunction<T>;
  // Die Daten, die in der Auswahl angezeigt werden sollen (wird nur verwendet, wenn dataService und dataFunction nicht gesetzt sind)
  @Input() data?: T[];
  // Die Felder, die in der Anzeige als Titel verwendet werden sollen
  @Input() titleFields: string[] = ['id'];
  // Die Funktion, die den Titel eines Objekts zurückgibt
  @Input() titleFunction?: (item: T) => string | undefined = undefined;
  // Die Felder, die in der Anzeige als Untertitel verwendet werden sollen
  @Input() subtitleFields: string[] = [];
  // Die Funktion, die den Untertitel eines Objekts zurückgibt
  @Input() subtitleFunction?: (item: T) => string | undefined = undefined;
  // Die Felder, die in der Anzeige als Badge verwendet werden sollen
  @Input() badgeFields?: string[] | undefined = undefined;

  /**
   *  Sollen die Daten beim nächsten Öffnen des Modals neu initialisiert werden, dann muss dieser Wert erhöht werden
   *  - Sinnvoll z.B. aufgrund von Filteränderungen in der übergebenen dataFunction
   */
  @Input() set reinitOnNextModalOpen(value: number) {
    this.dataInitialized = false;
  }

  ////////// HOSTBINDINGS
  // Fügt der Komponente automatisch die Klasse 'mits-form-item' hinzu
  @HostBinding('class') class = 'mits-form-item';

  ////////// OUTPUTS
  // Event, das ausgelöst wird, wenn die Auswahl geändert wird und das ausgewählte Objekt enthält
  @Output() changedSelection: EventEmitter<T | T[]> = new EventEmitter<
    T | T[]
  >();

  // Gets the selected Value as an observable
  valueAsObservable = new Observable<T[]>(observer => {
    if (!this.value) {
      observer.next([]);
      return;
    }
    if (Array.isArray(this.value)) {
      observer.next(this.value);
    } else {
      observer.next([this.value]);
    }
  });

  ////////// FLAGS
  // Flag, das anzeigt, ob das Modal zur Auswahl geöffnet ist
  isModalOpen = false;
  // Flag, das anzeigt, ob die Daten initialisiert wurden
  dataInitialized: boolean = false;
  // Sollen nur ausgewählte Objekte angezeigt werden
  showOnlySelected: boolean = false;

  ////////// INTERNE VARIABLEN
  //  Die Funktion, die die Daten bereitstellt
  #dataFunction: IndexDataFunction<T> | undefined;
  // Listener für das Trigger-Element
  #triggerListener?: (() => void)[] = [];
  // Der vorherige Wert der Auswahl
  #previousValue?: T | T[];

  constructor(
    public indexDataService: IndexDataService<T>,
    // Renderer zum Hinzufügen von Event-Listenern
    private renderer: Renderer2,
  ) {}

  /**
   * Setzt den Wert auf das Objekt mit der übergebenen ID
   * - ruft die dataFunctionID auf, um das Objekt zu laden
   * - findet das Objekt alternativ in den übergebenen Daten
   * TODO: Kommentieren und aufräumen
   * @param id
   */
  public setValueById(id: number | number[] | undefined | null): void {
    if (this.multiple) {
      const ids = Array.isArray(id)
        ? id
        : id !== undefined && id !== null
        ? [id]
        : [];
      const promises = ids.map(idItem => {
        if (this.dataFunctionFind) {
          return this.dataFunctionFind(idItem); // TODO: Bulkfind anwenden
        } else if (this.data) {
          // tslint:disable-next-line:triple-equals
          return Promise.resolve(this.data.find(item => item.id == idItem));
        } else {
          return Promise.resolve(undefined);
        }
      });
      Promise.all(promises).then(values => {
        this.value = values.filter(Boolean) as T[];
        this.changedSelection.emit(this.value);
      });
    } else {
      if (id) {
        if (this.dataFunctionFind) {
          this.dataFunctionFind(id as number).then(data => {
            this.value = data;
            this.changedSelection.emit(this.value);
          });
        } else if (this.data) {
          // tslint:disable-next-line:triple-equals
          this.value = this.data?.find(item => item.id == id);
          this.changedSelection.emit(this.value);
        }
      } else {
        this.value = undefined;
        this.changedSelection.emit(this.value);
      }
    }
  }

  /**
   * Überprüft, ob das übergebene Objekt ausgewählt ist
   * @param object - das Objekt, das überprüft werden soll
   * @returns true, wenn das Objekt ausgewählt ist, sonst false
   */
  isSelected(object: T): boolean {
    // Mehrfachauswahl
    if (this.multiple && Array.isArray(this.value))
      return this.value.some(item => item.id === object.id);
    // Einzelauswahl
    else if (!this.multiple && this.value)
      return (this.value as T).id === object.id;
    return false;
  }

  /**
   * Wird aufgerufen, wenn ein Checkbox-Element geändert wird
   * @param event - Das Event, das ausgelöst wurde
   * @param object - Das Objekt, das geändert wurde
   */
  onCheckboxChange(event: any, object: T): void {
    // Objekt hinzufügen, wenn es ausgewählt wurde
    if (event.detail.checked) this.addObject(object);
    // Objekt entfernen, wenn es abgewählt wurde
    else this.removeObject(object);
    this.value = [...(this.value as T[])];
    this.changedSelection.emit(this.value);
  }

  /**
   * Fügt ein Objekt zur Auswahl hinzu
   * @param object - das Objekt, das hinzugefügt werden soll
   */
  addObject(object: T): void {
    if (!this.value) this.value = [];
    if (
      Array.isArray(this.value) &&
      !this.value.some(item => item.id === object.id)
    )
      this.value.push(object);
  }

  /**
   * Entfernt ein Objekt aus der Auswahl
   * @param object - das Objekt, das entfernt werden soll
   */
  removeObject(object: T): void {
    if (Array.isArray(this.value))
      this.value = this.value.filter(item => item.id !== object.id);
  }

  ////////// GETTER

  /**
   * Gibt den Titel des Modals zurück
   */
  get title(): string {
    const prefix = ``;
    const suffix = `auswählen`;
    return `${prefix} ${this.label} ${suffix}`;
  }

  ////////// LIFECYCLE HOOKS

  /**
   * Wird nach der Initialisierung der Komponente aufgerufen
   * - Initialisiert den Listener für das Trigger-Element
   */
  ngAfterViewInit(): void {
    if (this.externalTrigger)
      this.initTriggerElementClickListener(this.externalTrigger);
  }

  /**
   * Wird aufgerufen, wenn Eingabedaten sich ändern
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.dataFunction) {
      if (changes.data) {
        if (this.data)
          this.#dataFunction = this.createFakeDataFunction(this.data);
        else this.#dataFunction = undefined;
      }
      if (changes.dataFunction) {
        if (this.dataFunction) this.#dataFunction = this.dataFunction;
        else this.#dataFunction = undefined;
      }
      this.dataInitialized = false;
      this.initIndexDataService();
    }
  }

  /**
   * Wird beim Zerstören der Komponente aufgerufen
   * - Beendet die Listener für das Trigger-Element
   */
  async ngOnDestroy(): Promise<void> {
    this.#triggerListener.forEach(listener => listener());
  }

  ////////// USER INTERACTION
  /**
   * Öffnet das Modal zur Auswahl eines Objekts
   * @protected
   */
  openModal(): void {
    this.#previousValue = this.value
      ? GlobalHelper.deepClone(this.value)
      : undefined;
    this.showOnlySelected = false;
    this.isModalOpen = true;
    this.initIndexDataService();
  }

  /**
   * Schließt das Modal zur Auswahl eines Objekts
   * @param submitSelection - Gibt an, ob die Auswahl übernommen werden soll
   * @protected
   */
  closeModal(submitSelection = true): void {
    if (!submitSelection) {
      this.value = this.#previousValue;
      this.changedSelection.emit(this.value);
    }
    this.isModalOpen = false;
  }

  /**
   * Wird aufgerufen, wenn der Nutzer nach unten scrollt, um weitere Objekte zu laden
   */
  onIonInfinite($event: any): void {
    this.indexDataService.loadNextPage().subscribe(async () => {
      await $event.target.complete();
    });
  }

  /**
   * Wird aufgerufen, um Angular zu informieren, wie die Items in der Liste identifiziert werden sollen
   * @param index - Der Index des Items
   * @param item - Das Item
   */
  trackById(index: number, item: T): number | string {
    return item.id;
  }

  /**
   * Wird aufgerufen, wenn die Seite bei der Paginierung geändert wird
   */
  pageChanged($event: number): void {
    this.indexDataService.loadSpecificPage($event).pipe(take(1)).subscribe();
  }

  /**
   * Wird aufgerufen, wenn der Scanner ein Ergebnis liefert
   * - Setzt den Suchbegriff auf das Ergebnis des Scanners
   * @param $event - Das Ergebnis des Scanners
   */
  scannedData($event: ScannerData) {
    const decodedText = $event?.decodedResult?.decodedText?.trim();
    if (!decodedText) return;

    const handleData = (object: T) => {
      if (this.multiple) {
        this.addObject(object);
        this.value = [...(this.value as T[])];
      } else {
        this.handleSingleSelection(object);
      }
      this.changedSelection.emit(this.value);
    };

    if (this.dataFunctionScan) {
      this.dataFunctionScan(decodedText)
        .then(handleData)
        .then(() => (this.showOnlySelected = true));
    } else {
      this.indexDataService.data$.pipe(take(2), skip(1)).subscribe(data => {
        if (data?.length === 1) handleData(data[0]);
      });
      this.indexDataService.searchField.setValue(decodedText);
    }
  }

  ////////// HILFSMETHODEN
  get noSelections(): boolean {
    return (
      !this.value || (Array.isArray(this.value) && this.value.length === 0)
    );
  }

  /**
   * Erstellt eine Funktion, die die übergebenen Daten zurückgibt
   * @param data - Die Daten, die zurückgegeben werden sollen
   * @private
   */
  private createFakeDataFunction(data: T[]): IndexDataFunction<T> {
    return (page, search) => {
      return Promise.resolve({
        data: this.filterLocalData(data, search), // Filtert nach dem Suchstring
        pagy: {
          page: 1,
          last: 1,
        },
      });
    };
  }

  /**
   * Filtert die übergebenen Daten nach dem Suchstring
   * @param data - Die Daten, die gefiltert werden sollen
   * @param search - Der Suchstring
   * @private
   */
  private filterLocalData(data: T[] = [], search = ''): T[] {
    const fieldsToSearch: string[] = [
      ...this.titleFields,
      ...this.subtitleFields,
    ];

    return data.filter(item =>
      fieldsToSearch.some(field =>
        String(item[field as keyof T])
          .toLowerCase()
          .includes(search.toLowerCase()),
      ),
    );
  }

  /**
   * Initialisiert den Listener für das Trigger-Element
   * - Der Listener wird auf das click Event des Elements gesetzt
   * - Beim Klick auf das Element wird das Modal geöffnet
   * - Gibt eine Fehlermeldung aus, wenn keine Trigger-ID übergeben wurde, oder das Element nicht gefunden wurde
   * @param triggerElementId - ID des Elements, auf dessen click Event das Modal geöffnet werden soll
   * @private
   */
  private initTriggerElementClickListener(
    triggerElementId: string | undefined,
  ): void {
    if (!triggerElementId) {
      console.error('Keine Trigger-ID zum Öffnen des Modals übergeben');
    } else {
      const triggerElements: NodeListOf<Element> = document.querySelectorAll(
        `[data-${TRIGGER_DATA_ATTRIBUTE}="${triggerElementId}"], #${triggerElementId}`,
      );
      if (triggerElements.length === 0) {
        console.error(`Kein Element mit der ID ${triggerElementId} gefunden`);
      } else {
        triggerElements.forEach(triggerElement => {
          const listener = this.renderer.listen(triggerElement, 'click', () => {
            this.openModal();
          });
          this.#triggerListener.push(listener);
        });
      }
    }
  }

  /**
   * Wählt ein Objekt aus
   * @param object - das ausgewählte Objekt oder undefined, wenn die Auswahl aufgehoben wird
   */
  public handleSingleSelection(object: T | undefined): void {
    // Neuen Wert setzen
    this.value = object;
    // Event auslösen
    this.changedSelection.emit(this.value);
    // Modal schließen, wenn nur ein Objekt ausgewählt wurde
    this.closeModal();
  }

  /**
   * Re-initialisiert den IndexDataService, wenn,
   * - dieser bisher noch nicht initialisiert wurde oder beim nächsten Modal-Öffnen neu initialisiert werden soll
   * - das Modal geöffnet ist
   * - und eine dataFunction gesetzt ist
   */
  private initIndexDataService(): void {
    if (!this.dataInitialized && this.isModalOpen && this.#dataFunction) {
      this.indexDataService.isLoading$
        .pipe(
          pairwise(),
          // Prüft auf die Sequenz isLoading===true --> isLoading===false
          filter(([prev, current]) => prev === true && current === false),
          take(1),
        )
        .subscribe(() => {
          this.dataInitialized = true;
        });
      this.indexDataService.init(this.#dataFunction, this.loadingType);
    }
  }

  /**
   * Wird aufgerufen, wenn der Nutzer auf den Button zum Wechseln zwischen der Anzeige
   * aller und der Anzeige der ausgewählten Objekte klickt
   */
  toggleShowOnlySelected() {
    this.showOnlySelected = !this.showOnlySelected;
  }
}
