import { Pipe, PipeTransform } from '@angular/core';
import { Translatable } from 'src/app/models/translatable';
import { LanguageService } from 'src/app/providers/language-service/language.service';

/**
 * TranslatePipe
 * ----------------------
 * TranslatePipe ist ein Pipe, der die Übersetzung von Translatable Objekten ermöglicht.
 */
@Pipe({ name: 'translate', standalone: true })
export class TranslatePipe<T extends Translatable<T>> implements PipeTransform {
  currentLang: string;
  constructor(langService: LanguageService) {
    this.currentLang = langService.currentLanguage.languageCode;
  }

  transform(obj: T, key: string & keyof T): string {
    const translation = obj.translations.find(
      t => t.locale === this.currentLang && t.key === key,
    );
    return translation?.value ?? (obj[key] as string);
  }

  transformByLang(obj: T, key: string & keyof T, lang: string): string {
    const translation = obj.translations.find(
      t => t.locale === lang && t.key === key,
    );
    return translation?.value ?? (obj[key] as string);
  }
}
