import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from 'src/app/pages/home/home.page';
import {
  DAMAGE_REPORTING_BASE_ROUTE,
  DAMAGE_REPORTING_ROUTES
} from "src/packages/damage-reporting/damage-report-management.routes";
import {
  FAILED_REQUEST_MONITORING_BASE_ROUTE
} from 'src/packages/failedRequestMonitoring/failedRequestMonitoring.routes';
import {INVENTORY_SCHEDULES_BASE_ROUTE} from 'src/packages/inventorySchedules/inventorySchedules.routes';

import {
  TOUR_CHECKLIST_SERVICE_TOKEN,
  TOUR_LOCATION_SERVICE_TOKEN,
  TOUR_MACHINE_SERVICE_TOKEN,
  TOUR_USER_SERVICE_TOKEN,
} from 'src/packages/tourManagement/tourManagement.tokens';
import {TOUR_MANAGEMENT_BASE_ROUTE} from '../packages/tourManagement/tourManagement-routing.module';
import {WAREHOUSE_BASE_ROUTE} from '../packages/warehouse/warehouse-routing.module';
import {CanDeactivateGuard} from './guards/canDeactivate.guard';
import {INVENTORIES_BASE_ROUTE, INVENTORIES_ROUTES} from './pages/backend/inventories/routes';
import {EMERGENCY_ORDER_BASE_ROUTE, EMERGENCY_ORDER_CHILD_ROUTES} from './pages/processing/emergency-order/routes';
import {AccessGuard} from './providers/access.guard';
import {ChecklistServiceTourAdapter} from './providers/model-services/adapters/checklist.service.tour.adapter';
import {LocationServiceTourAdapter} from './providers/model-services/adapters/location.service.tour.adapter';
import {MachineServiceTourAdapter} from './providers/model-services/adapters/machine.service.tour.adapter';
import {UserServiceTourAdapter} from './providers/model-services/adapters/user.service.tour.adapter';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home-page',
    pathMatch: 'full',
  },
  {
    path: 'home-page',
    component: HomePageComponent,
  },
  {
    path: 'statusboard',
    loadChildren: () =>
      import('./pages/backend/statusboard/statusboard.module').then(
        (m) => m.StatusboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'dev/offline-data',
    loadChildren: () =>
      import('./developers/pages/offline-data/offline-data.module').then(
        (m) => m.OfflineDataPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'dev/performance',
    loadChildren: () =>
      import(
        './developers/pages/performance-tester/performance-tester.module'
      ).then((m) => m.PerformanceTesterPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-inside',
    loadChildren: () =>
      import('./pages/backend/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'machines',
    loadChildren: () =>
      import('./pages/masterdata/machines/machines.module').then(
        (m) => m.MachinesPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'timelogs',
    loadComponent: () =>
      import('../packages/timeManagement/pages/timelogs/timelogs.page')
        .then((m) => m.TimelogsPage),
    canActivate: [AccessGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./pages/options/help/help.module').then((m) => m.HelpPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/backend/settings/settings.routes').then(
        (m) => m.SettingsRoutes
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('src/app/pages/processing/orders/orders.routes')
      .then((m) => m.OrdersRoutes),
    canActivate: [AccessGuard],
  },
  {
    path: 'inventories/active',
    loadChildren: () =>
      import(
        './pages/processing/inventories/inventories-active/inventories-active.module'
      ).then((m) => m.InventoriesActivePageModule),
    canActivate: [AccessGuard],
  },
  // Alte Version der Notfallbestellung
  // {
  //   path: 'emergency-order-old/:id',
  //   loadChildren: () =>
  //     import('./pages/processing/emergency-order-old/emergency-order.module').then(
  //       (m) => m.EmergencyOrderPageModule
  //     ),
  //   canActivate: [AccessGuard],
  // },
  {
    path: EMERGENCY_ORDER_BASE_ROUTE,
    children: EMERGENCY_ORDER_CHILD_ROUTES,
    canActivate: [AccessGuard],
  },
  {
    path: 'service_contracts',
    loadChildren: () =>
      import('./pages/backend/service-contracts/service-contracts.module').then(
        (m) => m.ServiceContractsPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'timelog-corrections',
    loadChildren: () =>
      import(
        './pages/backend/timelog-corrections/timelog-corrections.module'
      ).then((m) => m.TimelogCorrectionsPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'cars',
    loadChildren: () => import('./pages/masterdata/cars/cars.routes')
      .then((r) => r.CarsRoutes),
    canActivate: [AccessGuard],
  },
  {
    path: 'checklists',
    loadChildren: () =>
      import(
        '../packages/checklistManagement/pages/checklists/checklists.module'
      ).then((m) => m.ChecklistsPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import(
        '../packages/templateManagement/pages/templates/templates.module'
      ).then((m) => m.TemplatesPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: TOUR_MANAGEMENT_BASE_ROUTE,
    loadChildren: () =>
      import('../packages/tourManagement/tourManagement-routing.module').then(
        (m) => m.TourManagementRoutingModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: FAILED_REQUEST_MONITORING_BASE_ROUTE,
    loadChildren: () => import('../packages/failedRequestMonitoring/failedRequestMonitoring.routes')
        .then((m) => m.FailedRequestMonitoringRoutes),
    canActivate: [AccessGuard],
  },
  {
    path: INVENTORY_SCHEDULES_BASE_ROUTE,
    loadChildren: () => import('../packages/inventorySchedules/inventorySchedules.routes')
      .then((m) => m.InventorySchedulesRoutes),
    canActivate: [AccessGuard],
  },
  {
    path: INVENTORIES_BASE_ROUTE,
    children: INVENTORIES_ROUTES,
    canActivate: [AccessGuard],
  },
  {
    path: DAMAGE_REPORTING_BASE_ROUTE,
    children: DAMAGE_REPORTING_ROUTES,
    canActivate: [AccessGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/masterdata/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'mits-scanner-modal',
    loadChildren: () =>
      import(
        './components/mits-scanner-button/mits-scanner-modal/mits-scanner-modal.module'
      ).then((m) => m.MitsScannerModalPageModule),
    canActivate: [AccessGuard],
  },
  {
    path: 'dashboard-worker',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: 'quick-start',
    loadChildren: () =>
      import('./pages/processing/quick-start/quick-start.module').then(
        (m) => m.QuickStartPageModule
      ),
    canActivate: [AccessGuard],
  },
  {
    path: WAREHOUSE_BASE_ROUTE,
    loadChildren: () =>
      import('../packages/warehouse/warehouse-routing.module').then(
        (m) => m.WarehouseRoutingModule
      ),
    canActivate: [AccessGuard],
  },
  // Fallback route
  {
    path: '**',
    redirectTo: 'home-page',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, }),
  ],
  providers: [
    CanDeactivateGuard,
    { provide: TOUR_USER_SERVICE_TOKEN, useClass: UserServiceTourAdapter },
    {
      provide: TOUR_MACHINE_SERVICE_TOKEN,
      useClass: MachineServiceTourAdapter,
    },
    {
      provide: TOUR_LOCATION_SERVICE_TOKEN,
      useClass: LocationServiceTourAdapter,
    },
    {
      provide: TOUR_CHECKLIST_SERVICE_TOKEN,
      useClass: ChecklistServiceTourAdapter,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
