import { MitsLanguage } from '../app/providers/language-service/language.service';

export const supportedLanguages: MitsLanguage[] = [
  {
    language: $localize`Deutsch`,
    languageCode: 'de',
    flag: 'de',
  },
  {
    language: $localize`Englisch`,
    languageCode: 'en',
    flag: 'gb',
  },
  {
    language: $localize`Französisch`,
    languageCode: 'fr',
    flag: 'fr',
  },
];
