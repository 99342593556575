import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IonicModule} from '@ionic/angular';

@Component({
    selector: 'mits-footer',
    standalone: true,
    imports: [
        IonicModule,
    ],
    templateUrl: './mits-footer.component.html',
    styleUrls: ['./mits-footer.component.scss']
})
export class MitsFooterComponent {
    // Der Text, der auf dem Button angezeigt wird
    @Input() buttonText = '';
    // Die Farbe des Buttons (Standard = 'primary')
    @Input() buttonColor:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'success'
        | 'warning'
        | 'danger'
        | 'light'
        | 'medium'
        | 'dark' = 'primary';
    // Ist der Button deaktiviert? (Standard = false)
    @Input() disabled = false;
    // Event, das ausgelöst wird, wenn der Button geklickt wird
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
}
