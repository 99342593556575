import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OBJECT_SYNC_STORAGE,
  SyncProcessorService,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { map, Observable } from 'rxjs';
// internal
import { ErrorService } from 'src/app/providers/error.service';
import { EventsService } from 'src/app/providers/events.service';
import { ActiveInventoriesCounterService } from 'src/app/providers/state-services/active-intentories-counter.state.service';
import { StockObjectModel } from 'src/packages/warehouse/models/stock-object';
import { StockWithInventoryService } from 'src/packages/warehouse/providers/StockWithInventory.service';

@Injectable({
  providedIn: 'root',
})
export class StockObjectService extends StockWithInventoryService<StockObjectModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    private stateService: ActiveInventoriesCounterService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Warehouse::Stock',
      http,
      errorService,
      events,
      'warehouse/stocks/',
      'warehouse_stock',
      [],
      ['positions'],
      'inventory_required_by_id',
    );
    this.bulkSize = 10;
    this.bulkSyncActive = true;
  }

  //////////////////////////////////////////////////////////////
  //// Öffentliche Methoden

  /**
   * Suche durch den inventoryRequired Index nach allen Stocks, die eine Inventur benötigen
   */
  public allRequiredInventories(): Observable<StockObjectModel[]> {
    const keyrange = IDBKeyRange.bound(0, []);
    return this.indexedDBService
      .getAllByIndex<StoredData<StockObjectModel>>(
        OBJECT_SYNC_STORAGE,
        'inventoryRequired',
        keyrange,
      )
      .pipe(
        map((data: StoredData<StockObjectModel>[]): StockObjectModel[] =>
          data.map((d: StoredData<StockObjectModel>) => d.content),
        ),
      );
  }

  /**
   * Suche nach allen Stocks anhand des Attributs 'linked_to_id'
   * @param linkedToId - die ID des verknüpften Standorts des Warehouse::Stock
   * @return Promise<StockObjectModel> - der gefundene Stock oder undefined
   */
  public findByLinkedToId(linkedToId: number): Observable<StockObjectModel> {
    return this.indexedDBService
      .getByIndex<StoredData<StockObjectModel>>(
        OBJECT_SYNC_STORAGE,
        'linkedToId',
        [this.type, linkedToId],
      )
      .pipe(map(data => data.content));
  }

  /**
   * Erforderlichkeit der Inventur für einen Stock entfernen
   * - Online-Service: Setzt das Attribut 'inventory_required' auf false und 'inventory_required_by_id' auf null
   * @param id - Die ID des Stocks, für den die Erforderlichkeit der Inventur entfernt werden soll
   * @return Observable<StockObjectModel> - der aktualisierte Stock-Object
   */
  public cancelRequiredInventoryRemote(
    id: number,
  ): Observable<StockObjectModel> {
    return this.http.post<StockObjectModel>(
      `${this.endpointWithUrl}cancel_inventory/${id}`,
      '',
    );
  }

  //////////////////////////////////////////////////////////////
  //// Überschriebene Methoden des SyncService
  /**
   * Aktualisiert beim Speichern des Stocks die Anzahl der aktiven Inventuren im State-Service
   * @param obj - die Entität, die gespeichert wird
   * @param updatedAt - das Änderungsdatum
   * @protected
   */
  protected override async store(
    obj: StockObjectModel,
    updatedAt: string,
  ): Promise<boolean | StoredData<StockObjectModel>> {
    // Weitergeben der Entität an den State-Service, um Aktive Inventuren zu zählen
    const storedObj: boolean | StoredData<StockObjectModel> | void =
      await super.store(obj, updatedAt);
    if (storedObj && (storedObj as StoredData<StockObjectModel>)?.content) {
      this.stateService.onStore(
        (storedObj as StoredData<StockObjectModel>).content,
      );
    }
    return storedObj;
  }
}
