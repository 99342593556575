import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';

/**
 * Erstellt einen Index `locationNumber` im objectStore 'objects' der IndexedDB.
 * @param transaction
 */
export function locationNumberMigration(transaction: IDBTransaction) {
  const store = transaction.objectStore(OBJECT_SYNC_STORAGE);
  store.createIndex(
    'locationNumber',
    ['entityType', 'content.location_number'],
    {
      unique: false,
    },
  );
}
