import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SyncProcessorService} from '@vending/sync-engine-client';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {DamageCategoryModel} from 'src/packages/damage-reporting/data/models/damage-category.model';
import {ErrorService} from 'src/app/providers/error.service';
import {EventsService} from 'src/app/providers/events.service';
import {OfflineDataService} from 'src/app/providers/offlineData.service';

@Injectable({
    providedIn: 'root',
})
export class DamageCategoryOfflineDataService extends OfflineDataService<DamageCategoryModel> {
    constructor(
        public indexedDBService: NgxIndexedDBService,
        public syncProcessor: SyncProcessorService,
        public http: HttpClient,
        public errorService: ErrorService,
        public events: EventsService
    ) {
        super(
            indexedDBService,
            syncProcessor,
            'Reporting::DamageCategory',
            http,
            errorService,
            events,
            'reporting/damage_categories/',
            'reporting_damage_category',
            ['created_at', 'updated_at', 'created_by_id'],
            []
        );
    }

    /**
     * Name des Icons für die Klasse
     */
    public get iconName(): string {
        return 'warning';
    }
}
