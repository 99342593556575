import { Injectable } from '@angular/core';
import { DataService } from 'src/app/providers/data.service';
import { CarArticleModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CarArticleService extends DataService<CarArticleModel> {
  endpoint = 'car_management/articles/';
  objectName = 'article';
}
