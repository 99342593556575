import { Injectable } from '@angular/core';
import { OBJECT_SYNC_STORAGE } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom } from 'rxjs';
import { ArticleEanCode, ArticleModel } from 'src/app/models/articles';

// Store for the Article Eans
const ARTICLE_EAN_STORE = 'articleEan';

interface ArticleEanEntry {
  articleId: string;
  remoteId: string;
  amount: number;
}

@Injectable({
  providedIn: 'root',
})
export class ArticleEanService {
  constructor(private readonly idbService: NgxIndexedDBService) {}

  /**
   * Fügt einen EAN-Code in den Artikel-Ean Store ein
   * @param ean
   */
  public async storeEan(ean: ArticleEanCode): Promise<void> {
    try {
      await firstValueFrom(
        this.idbService.add(ARTICLE_EAN_STORE, {
          articleId: ean.article_id,
          remoteId: ean.remote_id,
          amount: ean.amount,
        }),
      );
    } catch (error) {
      console.error('Error storing EAN:', ean.id);
    }
  }

  public async deleteEan(ean: ArticleEanCode): Promise<void> {
    try {
      await firstValueFrom(
        this.idbService.delete(ARTICLE_EAN_STORE, ean.remote_id),
      );
    } catch (error) {
      console.error('Error deleting EAN:', ean.id);
    }
  }

  /**
   * Sucht einen Artikel anhand der Remote-ID des EAN-Codes
   * @param remoteId - die Remote-ID des EAN-Codes
   * @returns
   */
  public async articleByRemoteId(
    remoteId: string,
  ): Promise<ArticleModel | undefined> {
    const eanEntry = await firstValueFrom(
      this.idbService.getByIndex<ArticleEanEntry>(
        ARTICLE_EAN_STORE,
        'remoteId',
        remoteId,
      ),
    );
    const articleData = await firstValueFrom(
      this.idbService.getByKey<StoredData<ArticleModel>>(OBJECT_SYNC_STORAGE, [
        eanEntry.articleId,
        'Article',
      ]),
    );
    return articleData?.content;
  }
}
