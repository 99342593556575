import { Injectable } from '@angular/core';
import {
  FullTextEntry,
  OBJECT_SYNC_STORAGE,
} from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ArticleModel } from 'src/app/models/articles';
import { TranslatePipe } from 'src/app/pipes/translate/translate.pipe';
import { supportedLanguages } from 'src/environments/supported-languages';
import { LanguageService } from '../language-service/language.service';
import { WorkerMessage } from './fulltext-constants';

const SECONDS = 1000;
interface FulltextPayload {
  entityId: number;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class FulltextService {
  private worker?: Worker;
  private activity$ = new Subject<void>();
  private readonly debounceTime = 60 * SECONDS;
  private languages = supportedLanguages.map(l => l.languageCode);

  // New subject to track worker status
  private workerStatus$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly idbService: NgxIndexedDBService,
    private readonly langService: LanguageService,
    private readonly translatePipe: TranslatePipe<ArticleModel>,
  ) {
    this.monitorWorkerActivity();
  }

  // Observable to expose worker status
  public get WorkerStatus(): Observable<boolean> {
    return this.workerStatus$.asObservable();
  }

  public store(obj: ArticleModel, key: string & keyof ArticleModel): void {
    this.startWorker();
    for (const lang of this.languages) {
      this.postMessage({
        action: 'store',
        storeName: this.StoreName(lang),
        payload: [
          {
            entityId: obj.id,
            value: this.translatePipe.transformByLang(obj, key, lang),
          },
        ],
      });
    }
  }

  public bulkDelete(objs: ArticleModel[], key: keyof ArticleModel): void {
    this.startWorker();
    for (const lang of this.languages) {
      const payload: FulltextPayload[] = objs.map(obj => ({
        entityId: obj.id,
        value: this.translatePipe.transformByLang(obj, key, lang),
      }));
      this.postMessage({
        action: 'delete',
        storeName: this.StoreName(lang),
        payload,
      });
    }
  }

  public bulkStore(
    objs: ArticleModel[],
    key: string & keyof ArticleModel,
  ): void {
    this.startWorker();
    for (const lang of this.languages) {
      console.log('bulkStore', objs);
      const message: WorkerMessage = {
        action: 'store',
        storeName: this.StoreName(lang),
        payload: objs.map(obj => ({
          entityId: obj.id,
          value: this.translatePipe.transformByLang(obj, key, lang),
        })),
      };
      console.log('bulkStore', message);
      this.postMessage(message);
    }
  }

  public delete(obj: ArticleModel, key: keyof ArticleModel): void {
    this.startWorker();
    for (const lang of this.languages) {
      const value = this.translatePipe.transformByLang(obj, key, lang);
      this.postMessage({
        action: 'delete',
        storeName: this.StoreName(lang),
        payload: [{ entityId: obj.id, value }],
      });
    }
  }

  public search(val: string, type: string): Observable<ArticleModel[]> {
    if (val.length < 3) {
      return of([]);
    }
    val = val.toLocaleLowerCase();
    return new Observable(observer => {
      this.idbService
        .getByKey<FullTextEntry>(this.currentStoreName, [val, type])
        .subscribe({
          next: res => {
            if (!res) {
              observer.next([]);
              return;
            }
            const keys = res.references.map(r => this.createValidKey(r, type));
            this.bulkFind(keys).subscribe({
              next: res => {
                res.map(article => {
                  article.name = this.translatePipe.transform(article, 'name');
                });
                observer.next(res);
              },
              error: err => observer.error(err),
            });
          },
          error: err => observer.error(err),
        });
    });
  }

  private postMessage(message: WorkerMessage) {
    this.worker.postMessage(message);
  }

  private get currentStoreName() {
    return this.StoreName(this.langService.currentLanguage?.languageCode);
  }

  private StoreName(lang: string) {
    return 'fulltext' + lang;
  }

  private startWorker() {
    if (typeof Worker === 'undefined')
      throw new Error('Web Worker are not supported');
    if (!this.worker) {
      this.worker = new Worker(new URL('./fulltext.worker', import.meta.url));
      this.worker.onmessage = event => {
        const { message } = event.data;
        if (message === 'success') {
          this.notifyActivity();
        }
      };
      this.workerStatus$.next(true); // Worker started
    }
  }

  private terminateWorker() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = undefined;
      this.workerStatus$.next(false); // Worker terminated
    }
  }

  private bulkFind(keys: IDBValidKey[]): Observable<ArticleModel[]> {
    return this.idbService
      .bulkGet<StoredData<ArticleModel>[]>(OBJECT_SYNC_STORAGE, keys)
      .pipe(
        map(res => (res as StoredData<ArticleModel>[]).map(e => e.content)),
      );
  }

  private createValidKey(id: number, type: string) {
    return [id, type];
  }

  private notifyActivity() {
    this.activity$.next();
  }

  private monitorWorkerActivity() {
    this.activity$
      .pipe(
        debounceTime(this.debounceTime),
        tap(() => this.terminateWorker()),
      )
      .subscribe();
  }
}
