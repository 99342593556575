// tslint:disable:no-console
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ArticleModel } from 'src/app/models/articles';
import { MachineModel } from 'src/app/models/machine';
import { OrderModel } from 'src/app/models/order';
import { OrderStateManager } from 'src/app/pages/processing/orders/order-details/helper/orderStateManager';
import { ErrorService } from 'src/app/providers/error.service';
import { LocalDataService } from 'src/app/providers/localStorageData.service';
import { ArticleService } from 'src/app/providers/model-services/article.service';
import { MachineService } from 'src/app/providers/model-services/machines/machine.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';
import { BufferstockModel, StockObjectModel } from 'src/packages/warehouse';
import {
  StockPositionDisposalModel,
  StockPositionModel,
} from 'src/packages/warehouse/models/position';
import { StockMovementModel } from 'src/packages/warehouse/models/stockMovement';
import {
  BufferstockService,
  StockMovementService,
} from 'src/packages/warehouse/providers';
import { StockObjectService } from 'src/packages/warehouse/providers/stockObject.service';

/**
 * Model zur Zwischenspeicherung der StockMovements zu einer Order im LocalStorage via LocalDataService
 * - Dient dem Rückbuchen von StockMovements, falls die Order abgebrochen wird
 */
interface OrderStockMovementsQueue {
  // Die ID der Order zu der die StockMovements gehören
  id: number;
  // Die StockMovements die zur Order gehören
  movements: StockMovementModel[];
}

/**
 * Service zum Zwischenspeichern von StockMovements zu einer Order (#767)
 * - Ermöglicht das Rückbuchen der zu einer Order getätigten StockMovements, falls die Order abgebrochen wird
 */
@Injectable({ providedIn: 'root' })
export class OrderMovementsQueueService {
  // Hält die Zuordnung von StockMovements für die Orders, die noch nicht abgeschlossen oder abgebrochen wurden
  private queuedMovements: LocalDataService<OrderStockMovementsQueue>;

  constructor(
    private readonly stockMovementService: StockMovementService,
    private readonly orderStateManager: OrderStateManager,
    private readonly bufferstockService: BufferstockService,
    private readonly errorService: ErrorService,
    private readonly articleService: ArticleService,
    private readonly stockObjectService: StockObjectService,
    private readonly machineService: MachineService,
  ) {
    this.queuedMovements = new LocalDataService<OrderStockMovementsQueue>(
      'orderStockMovementsQueue',
    );
  }

  ////// PUBLIC SERVICE METHODS

  /**
   * Funktion zum Hinzufügen einer StockMovement zur aktuellen Order
   * @param movement - Die StockMovement die hinzugefügt werden soll
   */
  public enqueueMovement(movement: StockMovementModel): void {
    const currentOrder: OrderModel = this.getCurrentOrder();
    if (!currentOrder) {
      console.error(
        'Keine Order im OrderStateManager gefunden für folgende Warehouse::StockMovement: ',
        movement,
      );
    }
    // Queue-Objekt im LocalStorage anlegen, falls noch nicht vorhanden
    const orderMovementsQueue: OrderStockMovementsQueue =
      this.queuedMovements.find(currentOrder.id)?.content ??
      this.createNewOrderMovementsQueue(currentOrder.id);
    orderMovementsQueue.movements.push(movement);
    this.queuedMovements.save(orderMovementsQueue);
  }

  /**
   * Funktion zum Rückbuchen der StockMovements für eine Order
   * @param orderId - Die OrderId für die die StockMovements zurückgebucht werden sollen
   * @param machineId - Optional: Die ID der Maschine, für die die StockMovements zurückgebucht werden sollen
   */
  public async rollbackMovementsForOrder(
    orderId: number,
    machineId?: number,
  ): Promise<void> {
    console.debug(
      '[OrderBookingsQueueService][rollbackMovementsForOrder(' +
        orderId +
        ')]: Rückbuchung für:',
      orderId,
      machineId,
    );
    // StockMovements für die Order bzw. Order/Maschine aus der Queue laden
    const stockMovements: StockMovementModel[] | undefined =
      await this.loadStockMovements(orderId, machineId);

    if (stockMovements?.length > 0) {
      // Rückabwicklung der StockMovements
      for (const movement of stockMovements) {
        // Modell für die Gegenbewegung erstellen
        const counterMovement: StockMovementModel =
          this.generateCounterMovement(movement);
        const counterMovementSavedToSyncQueue: boolean =
          await this.saveStockMovementToSyncQueue(counterMovement);
        if (counterMovementSavedToSyncQueue) {
          // Bestände von Bufferstock und Maschinen-Stock in den lokalen Daten korrigieren
          await this.rollbackLocalStocks(counterMovement);
          // Löschen der Bewegung aus der Queue im Local Storage
          this.queuedMovements.delete(orderId);
        }
      }
    } else {
      console.debug(
        '[OrderBookingsQueueService][redoMovementsForOrder(' +
          orderId +
          ')]: Keine Bewegungen gefunden.',
      );
    }
  }

  /**
   * Funktion zum Löschen der Bewegungen für einen Auftrag aus der Queue im Local Storage
   * @param orderId - Die OrderId für die die Bewegungen gelöscht werden sollen
   */
  public deleteMovementsForOrder(orderId: number): void {
    console.debug(
      '[OrderBookingsQueueService][deleteMovementsForOrder(' +
        orderId +
        ')]: Löschen der Movements Queue',
    );
    this.queuedMovements.delete(orderId);
  }

  ////// Hilfsfunktionen //////

  /**
   * Funktion zum Laden der StockMovements basierend auf orderId und optional machineId
   * @param orderId - Die OrderId für die die StockMovements geladen werden sollen
   * @param machineId - Optional: Die ID der Maschine, für die die StockMovements geladen werden sollen
   * @private
   * @returns Ein Array der StockMovements oder undefined
   */
  private async loadStockMovements(
    orderId: number,
    machineId?: number,
  ): Promise<StockMovementModel[] | undefined> {
    if (machineId)
      return await this.getStockMovementsForMachineOnly(orderId, machineId);
    else return this.getStockMovementsForOrder(orderId);
  }

  /**
   * Funktion zum Korrigieren der Bestände in den lokalen Daten von Bufferstock und Machine-Stock basierend auf Rückbuchung
   * @param counterMovement - Die generierte Rückbuchung zu der ursprünglichen StockMovement
   * @private
   */
  private async rollbackLocalStocks(
    counterMovement: StockMovementModel,
  ): Promise<void> {
    if (
      counterMovement.type === 'Warehouse::StockMovements::MachineTrashMovement'
    ) {
      await this.rollbackLocalStocksForMachineTrashMovement(counterMovement);
    } else if (
      counterMovement.type === 'Warehouse::StockMovements::OrderMovement'
    ) {
      await this.rollbackLocalStocksForOrderMovement(counterMovement);
    }
  }

  /**
   * Funktion zum Rückbuchen der Bestände für eine MachineTrashMovement
   * - Entfernt die Menge der TrashMovement aus dem Bufferstock und fügt sie dem Maschinen-Stock wieder hinzu
   * @param counterMovement - Die generierte Rückbuchung zu der ursprünglichen TrashMovement mit negativer Menge
   * @private
   * @returns Ein Promise, das auf die Beendigung des Rückbuchungsprozesses wartet
   */
  private async rollbackLocalStocksForMachineTrashMovement(
    counterMovement: StockMovementModel,
  ): Promise<void> {
    await this.updateBufferstockPositionDisposal(
      counterMovement.source_id, // ID des Warehouse::Bufferstock
      counterMovement.article_id,
      counterMovement.amount, // amount ist negativ und führt dazu, dass Entsorgungslager-Position wieder entfernt wird
    );
    await this.updateMachineStockPosition(
      counterMovement.target_id, // ID des Warehouse::Stock der Maschine
      counterMovement.article_id,
      counterMovement.amount * -1, // Menge wieder auf dem Maschinen-Stock hinzufügen
    );
  }

  /**
   * Funktion zum Rückbuchen der Bestände für eine OrderMovement
   * - Fügt die Menge der OrderMovement dem Bufferstock wieder hinzu und entfernt sie aus dem Maschinen-Stock
   * @param counterMovement - Die generierte Rückbuchung zu der ursprünglichen OrderMovement mit negativer Menge
   * @private
   * @returns Ein Promise, das auf die Beendigung des Rückbuchungsprozesses wartet
   */
  private async rollbackLocalStocksForOrderMovement(
    counterMovement: StockMovementModel,
  ): Promise<void> {
    await this.updateBufferstockPosition(
      counterMovement.source_id, // ID des Warehouse::Bufferstock
      counterMovement.article_id,
      counterMovement.amount * -1, // Menge wieder auf dem Bufferstock hinzufügen
    );
    await this.updateMachineStockPosition(
      counterMovement.target_id, // ID des Warehouse::Stock der Maschine
      counterMovement.article_id,
      counterMovement.amount, // amount ist negativ und führt dazu, dass Maschinen-Stock-Position wieder entfernt wird
    );
  }

  /**
   * Funktion zum Abrufen der StockMovements zu einer Order aus der Queue
   * @param orderId -  Die ID der Order, zu der die StockMovements abgerufen werden sollen
   * @param machineId - Die ID der Maschine, nach der die StockMovements über die Stock-ID gefiltert werden sollen
   * @private
   * @returns Die StockMovements die zur Order und Maschine gehören oder ein leeres Array
   */
  private async getStockMovementsForMachineOnly(
    orderId: number,
    machineId: number,
  ): Promise<StockMovementModel[]> {
    const orderMovements: StockMovementModel[] =
      this.getStockMovementsForOrder(orderId);
    if (orderMovements.length === 0) return [];
    // Machine zur ID laden
    const machine: MachineModel = await firstValueFrom(
      this.machineService.find(machineId),
    ).catch(() => undefined);
    if (!machine?.customer_location_id) return [];
    // StockObject zur Machine über machine.linked_to laden
    const stockObject: StockObjectModel = await firstValueFrom(
      this.stockObjectService.findByLinkedToId(machine.customer_location_id),
    ).catch(() => undefined);
    if (!stockObject) return [];
    // Nach Stock-ID gefilterte Movements zurückgeben
    return orderMovements.filter(
      (movement: StockMovementModel) => movement.target_id === stockObject.id,
    );
  }

  /**
   * Funktion zum Abrufen der StockMovements zu einer Order aus der Queue
   * @param orderId - Die ID der Order, zu der die StockMovements abgerufen werden sollen
   * @private
   * @returns Die StockMovements zu der Order oder ein leeres Array
   */
  private getStockMovementsForOrder(orderId: number): StockMovementModel[] {
    const stockMovements: StockMovementModel[] =
      this.queuedMovements.find(orderId)?.content?.movements;
    return stockMovements ? stockMovements : [];
  }

  /**
   * Funktion zum Erstellen der Rückbuchung zu der Übergebenen StockMovement
   * @param movement - Die StockMovement zu der die Rückbuchung erstellt werden soll
   * @private
   * @returns Die Rückbuchung mit vertauschter Quell und Ziel-Id und invertiertem Betrag
   */
  private generateCounterMovement(
    movement: StockMovementModel,
  ): StockMovementModel {
    return {
      amount: movement.amount * -1, // Lediglich die Menge wird invertiert
      type: movement.type,
      article_id: movement.article_id,
      source_id: movement.source_id,
      target_id: movement.target_id,
      created_at: new Date(),
      updated_at: new Date(),
      booked_at: new Date(),
    } as StockMovementModel;
  }

  /**
   * Hilfsfunktion zum Abrufen der aktuellen Order
   * @returns Die aktuelle Order oder undefined
   */
  private getCurrentOrder(): OrderModel | undefined {
    return this.orderStateManager.currentOrder;
  }

  /**
   * Hilfsfunktion zum Erstellen eines neuen OrderStockMovementsQueue-Objekts
   * @param orderId - Die ID der Order
   * @returns Ein neues OrderStockMovementsQueue-Objekt
   */
  private createNewOrderMovementsQueue(
    orderId: number,
  ): OrderStockMovementsQueue {
    return { id: orderId, movements: [] };
  }

  ////// UPDATE POSITIONS

  /**
   * Funktion zum Erzeugen oder Bearbeiten einer DisposalPosition zu einem Bufferstock
   * @param bufferstockId - Die ID des Bufferstocks, zu dem die DisposalPosition hinzugefügt werden soll
   * @param articleId - Die ID des Artikels, für den die DisposalPosition erstellt werden soll
   * @param amount - Die Menge der DisposalPosition
   */
  private async updateBufferstockPositionDisposal(
    bufferstockId: number,
    articleId: number,
    amount: number = 0,
  ): Promise<void> {
    console.debug(
      '[OrderBookingsQueueService][updateBufferstockPositionDisposal()]: ' +
        'Aktualisieren der Position Disposals',
      bufferstockId,
      articleId,
      amount,
    );
    if (!bufferstockId || !articleId || amount === 0) return;

    // Bufferstock laden und Methode abbrechen, falls nicht gefunden
    const bufferstock: BufferstockModel | undefined =
      await this.findBufferstockLocal(bufferstockId);
    if (!bufferstock) return;

    // Bestehende DisposalPosition aktualisieren oder neue DisposalPosition hinzufügen
    const disposalPosition: StockPositionDisposalModel =
      this.getDisposalPositionForArticleId(bufferstock, articleId);
    if (disposalPosition)
      this.editExistingDisposalPositionOnBufferstock(disposalPosition, amount);
    else
      await this.addDisposalPositionToBufferstock(
        bufferstock,
        articleId,
        amount,
      );

    // Aktualisierten Bufferstock lokal speichern
    await this.saveBufferstockLocal(bufferstock);
    console.debug(
      '[OrderBookingsQueueService][updateBufferstockPositionDisposal()]: ' +
        'Bufferstock lokal aktualisiert',
      bufferstock,
    );
  }

  /**
   * Funktion zum Aktualisieren der Position eines Stocks
   * @param stockId - Die ID des Stocks, dessen Position aktualisiert werden soll
   * @param articleId - Die ID des Artikels, für den die Position aktualisiert werden soll
   * @param amount - Die Menge der Position
   */
  private async updateMachineStockPosition(
    stockId: number,
    articleId: number,
    amount: number = 0,
  ): Promise<void> {
    console.debug(
      '[OrderBookingsQueueService][updateMachineStockPosition()]: ' +
        'Aktualisieren der Position für',
      'Warehouse::Stock: ',
      stockId,
      'Artikel: ',
      articleId,
      'Menge: ',
      amount,
    );
    if (!stockId || !articleId || amount === 0) return;

    // StockObject laden und Methode abbrechen, falls nicht gefunden
    const stockObject: StockObjectModel | undefined =
      await this.findStockObjectLocal(stockId);
    if (!stockObject) return;

    // Bestehende Position aktualisieren oder neue Position hinzufügen
    const position: StockPositionModel = this.getPositionForArticleId(
      stockObject,
      articleId,
    );
    if (position) this.editExistingPositionOnStockObject(position, amount);
    else await this.addPositionToStockObject(stockObject, articleId, amount);

    // Aktualisierten Bufferstock lokal speichern
    await this.saveStockObjectLocal(stockObject);
    console.debug(
      '[OrderBookingsQueueService][updateMachineStockPosition()]: ' +
        'Warehouse::Stock: aktualisiert',
      stockObject,
    );
  }

  /**
   * Funktion zum Aktualisieren der Position eines Bufferstocks
   * @param bufferstockId - Die ID des Bufferstocks, dessen Position aktualisiert werden soll
   * @param articleId - Die ID des Artikels, für den die Position aktualisiert werden soll
   * @param amount - Die Menge der Position
   */
  private async updateBufferstockPosition(
    bufferstockId: number,
    articleId: number,
    amount: number = 0,
  ): Promise<void> {
    console.debug(
      '[OrderBookingsQueueService][updateBufferstockPosition()]: ' +
        'Aktualisieren der Position für',
      'Warehouse::Bufferstock: ',
      bufferstockId,
      'Artikel: ',
      articleId,
      'Menge: ',
      amount,
    );
    if (!bufferstockId || !articleId || amount === 0) return;

    // Bufferstock laden und Methode abbrechen, falls nicht gefunden
    const bufferstock: BufferstockModel | undefined =
      await this.findBufferstockLocal(bufferstockId);
    if (!bufferstock) return;

    // Bestehende Position aktualisieren oder neue Position hinzufügen
    const position: StockPositionModel = this.getPositionForArticleId(
      bufferstock,
      articleId,
    );
    if (position) this.editExistingPositionOnStockObject(position, amount);
    else await this.addPositionToStockObject(bufferstock, articleId, amount);

    // Aktualisierten Bufferstock lokal speichern
    await this.saveBufferstockLocal(bufferstock);
    console.debug(
      '[OrderBookingsQueueService][updateBufferstockPosition()]: ' +
        'Warehouse::Bufferstock aktualisiert',
      bufferstock,
    );
  }

  /**
   * Hilfsfunktion zum Erzeugen und Hinzufügen einer neuen DisposalPosition zu einem Bufferstock
   * @param bufferstock - Der Bufferstock, zu dem die DisposalPosition hinzugefügt werden soll
   * @param articleId - Die ID des Artikels, für den die DisposalPosition erstellt werden soll
   * @param amount - Die Menge der DisposalPosition
   * @private
   * @returns Der Bufferstock mit der hinzugefügten DisposalPosition
   */
  private async addDisposalPositionToBufferstock(
    bufferstock: BufferstockModel,
    articleId: number,
    amount: number,
  ): Promise<void> {
    const article: ArticleModel = await this.findArticleLocal(articleId);
    const disposalPosition: StockPositionDisposalModel = {
      amount,
      article_id: articleId,
      article,
      bufferstock_id: bufferstock.id,
      bufferstock,
    };
    if (!bufferstock.position_disposals) bufferstock.position_disposals = [];
    bufferstock.position_disposals.push(disposalPosition);
  }

  /**
   * Hilfsfunktion zum Erzeugen und Hinzufügen einer neuen Position zu einem StockObject
   * @param stockObject - Das StockObject, zu dem die Position hinzugefügt werden soll
   * @param articleId - Die ID des Artikels, für den die Position erstellt werden soll
   * @param amount - Die Menge der Position
   * @private
   */
  private async addPositionToStockObject(
    stockObject: StockObjectModel,
    articleId: number,
    amount: number,
  ): Promise<void> {
    if (!stockObject) return;
    const article: ArticleModel = await this.findArticleLocal(articleId);
    const position: StockPositionModel = {
      amount,
      article_id: articleId,
      article_name: article?.name,
      article_number: article?.article_number,
    };
    if (!stockObject.positions) stockObject.positions = [];
    stockObject.positions.push(position);
  }

  /**
   * Hilfsfunktion zum Bearbeiten einer bestehenden DisposalPosition auf einem Bufferstock
   * @param disposalPosition - Die DisposalPosition, die bearbeitet werden soll
   * @param amount - Die Menge, die zur DisposalPosition hinzugefügt werden soll
   * @private
   */
  private editExistingDisposalPositionOnBufferstock(
    disposalPosition: StockPositionDisposalModel,
    amount: number,
  ): void {
    if (disposalPosition) {
      if (GlobalHelper.isZero(disposalPosition.amount))
        disposalPosition.amount = 0;
      disposalPosition.amount += amount;
    }
  }

  /**
   * Hilfsfunktion zum Bearbeiten einer bestehenden Position auf einem StockObject
   * @param position - Die Position, die bearbeitet werden soll
   * @param amount - Die Menge, die zur Position hinzugefügt werden soll
   * @private
   */
  private editExistingPositionOnStockObject(
    position: StockPositionModel,
    amount: number,
  ): void {
    if (position) {
      if (GlobalHelper.isZero(position.amount)) position.amount = 0;
      position.amount += amount;
    }
  }

  /**
   * Hilfsfunktion zum Finden einer DisposalPosition für einen Artikel aus einem Bufferstock
   * @param bufferstock - Der Bufferstock, in dem die DisposalPosition gesucht werden soll
   * @param articleId - Die ID des Artikels, für den die DisposalPosition gesucht werden soll
   * @private
   * @returns Die gefundene DisposalPosition oder undefined
   */
  private getDisposalPositionForArticleId(
    bufferstock: BufferstockModel,
    articleId: number,
  ): StockPositionDisposalModel | undefined {
    return bufferstock?.position_disposals?.find(
      (pos: StockPositionDisposalModel): boolean =>
        pos.article_id === articleId,
    );
  }

  /**
   * Hilfsfunktion zum Finden einer Position für einen Artikel aus einem StockObject
   * @param stockObject - Das StockObject, in dem die Position gesucht werden soll
   * @param articleId - Die ID des Artikels, für den die Position gesucht werden soll
   * @private
   * @returns Die gefundene Position oder undefined
   */
  private getPositionForArticleId(
    stockObject: StockObjectModel,
    articleId: number,
  ): StockPositionModel | undefined {
    return stockObject?.positions.filter(
      (i: StockPositionModel) => i.article_id === articleId,
    )[0];
  }

  //////////// CRUD-HELPERS

  /**
   * Funktion zum Speichern einer StockMovement in der SyncQueue
   * @param movement - Die StockMovement die gespeichert werden soll
   * @private
   * @returns True, falls die StockMovement erfolgreich in der Warteschlange gespeichert wurde, sonst false
   */
  private async saveStockMovementToSyncQueue(
    movement: StockMovementModel,
  ): Promise<boolean> {
    return await firstValueFrom(this.stockMovementService.save(movement))
      .then(() => true)
      .catch(err => {
        this.errorService.handle(err);
        console.error(
          '[OrderBookingsQueueService][saveStockMovement()]: Die StockMovement konnte nicht in der Warteschlange gespeichert werden:',
          movement,
          err,
        );
        return false;
      });
  }

  /**
   * Hilfsfunktion zum Abrufen eines Bufferstocks aus den lokalen Daten
   * @param bufferstockId - Die ID des Bufferstocks, der aus den lokalen Daten geladen werden soll
   * @private
   * @returns Der gefundene Bufferstock oder undefined
   */
  private async findBufferstockLocal(
    bufferstockId: number,
  ): Promise<BufferstockModel | undefined> {
    return await firstValueFrom(
      this.bufferstockService.find(bufferstockId),
    ).catch(err => {
      this.errorService.handle(err);
      console.error(
        '[OrderBookingsQueueService][getBufferstock()]: Bufferstock mit der folgenden ID nicht gefunden:',
        bufferstockId,
        err,
      );
      return undefined;
    });
  }

  /**
   * Hilfsfunktion zum Speichern eines Bufferstocks in den lokalen Daten
   * @param bufferstock - Der Bufferstock, der in den lokalen Daten gespeichert werden soll
   * @private
   * @returns Ein Promise, das auf die Beendigung des Speicherns wartet
   */
  private async saveBufferstockLocal(
    bufferstock: BufferstockModel,
  ): Promise<void> {
    await this.bufferstockService.saveLocalForce(bufferstock).catch(err => {
      this.errorService.handle(err);
      console.error(
        '[OrderBookingsQueueService][saveBufferstock()]: Der Bufferstock konnte lokal nicht gespeichert werden:',
        bufferstock,
        err,
      );
    });
  }

  /**
   * Hilfsfunktion zum Abrufen eines StockObjects aus den lokalen Daten
   * @param stockId - Die ID des StockObjects, der aus den lokalen Daten geladen werden soll
   * @private
   * @returns Das gefundene StockObject oder undefined
   */
  private async findStockObjectLocal(
    stockId: number,
  ): Promise<StockObjectModel | undefined> {
    return await firstValueFrom(this.stockObjectService.find(stockId)).catch(
      err => {
        this.errorService.handle(err);
        console.error(
          '[OrderBookingsQueueService][findStockObjectLocal()]: StockObject mit der folgenden ID nicht gefunden:',
          stockId,
          err,
        );
        return undefined;
      },
    );
  }

  /**
   * Hilfsfunktion zum Speichern eines StockObjects in den lokalen Daten
   * @param stockObject - Das StockObject, das in den lokalen Daten gespeichert werden soll
   * @private
   * @returns Ein Promise, das auf die Beendigung des Speicherns wartet
   */
  private async saveStockObjectLocal(
    stockObject: StockObjectModel,
  ): Promise<void> {
    await this.stockObjectService.saveLocalForce(stockObject).catch(err => {
      this.errorService.handle(err);
      console.error(
        '[OrderBookingsQueueService][saveStockObjectLocal()]: Dasa StockObject konnte lokal nicht gespeichert werden:',
        stockObject,
        err,
      );
    });
  }

  /**
   * Hilfsfunktion zum Abrufen eines Artikels aus den lokalen Daten
   * @param articleId - Die ID des Artikels, der aus den lokalen Daten geladen werden soll
   * @private
   * @returns Der gefundene Artikel oder undefined
   */
  private async findArticleLocal(
    articleId: number,
  ): Promise<ArticleModel | undefined> {
    return await firstValueFrom(this.articleService.find(articleId)).catch(
      err => {
        this.errorService.handle(err);
        console.error(
          '[OrderBookingsQueueService][getArticle()]: Artikel mit der folgenden ID nicht gefunden:',
          articleId,
          err,
        );
        return undefined;
      },
    );
  }
}
