import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncProcessorService } from '@vending/sync-engine-client';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, catchError, firstValueFrom, retry } from 'rxjs';
import { ArticleModel } from 'src/app/models/articles/article';
import { ErrorService } from '../error.service';
import { EventsService } from '../events.service';
import { FulltextService } from '../fulltext-service/fulltext.service';
import { OfflineDataService } from '../offlineData.service';
import { ArticleEanService } from './article-ean.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends OfflineDataService<ArticleModel> {
  public useSubstring: boolean;

  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService,
    private readonly fulltextService: FulltextService,
    private readonly articleEanService: ArticleEanService,
  ) {
    super(
      indexedDBService,
      syncProcessor,
      'Article',
      http,
      errorService,
      events,
      'articles/',
      'article',
      ['created_at', 'updated_at', 'created_by_id', 'unit', 'packaging_unit'],
      ['eans'],
      'ean',
    );
    this.bulkSize = 50;
    this.bulkSyncActive = true;
  }

  /**
   * Validiert die Offlinedaten in jedem Sync
   * @return {boolean} true, wenn validiert werden soll
   */
  override get checkEachSync(): boolean {
    return this.checkAlreadyValidated();
  }

  /**
   * Name des Icons für die Klasse
   * @return {string}
   */
  public get iconName(): string {
    return 'pricetag';
  }

  /**
   * loads article with name 'Fahrzone'
   * @returns geet-request to articles/drive_zone
   */
  filterForDriveZone(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'drive_zone')
      .pipe(retry(1), catchError(this.errorService.convert));
  }

  /**
   * Gibt die ersten 25 Artikel zurück die gefunden werden
   * Falls keine gefunden werden, werden nur die ersten 25 Artikel zurückgegeben (ohne Suche)
   * @param searchText
   * @returns
   */
  public async fastLimitedSearch(searchText: string): Promise<ArticleModel[]> {
    if (searchText?.length < 3) {
      const foundArticles = await this.localAllwithPaging(1);
      return foundArticles.data;
    } else {
      const articles = await firstValueFrom(this.fulltextSearch(searchText));
      return articles;
    }
  }

  /**
   * Fulltext neu aufbauen
   */
  public async rebuildFullText(article?: ArticleModel) {
    let articles: StoredData<ArticleModel>[];
    if (!article) articles = await firstValueFrom(this.localAllStoredData());
    else articles = [this.createStoredData(article)];
    for (const article of articles) {
      this.fulltextService.store(article.content, 'name');
    }
  }

  protected override async bulkStore(objs: ArticleModel[]): Promise<void> {
    if (objs.length === 0) return;
    if (this.initialSync) await this.bulkStoreFulltextWithEans(objs);
    else {
      const articleIds = objs.map(obj => obj.id);
      const currentObjs = await firstValueFrom(this.bulkFind(articleIds));
      this.fulltextService.bulkDelete(currentObjs, 'name');

      // Neue Artikel, nicht in der idb
      const newObjects = objs.filter(
        obj => !currentObjs.find(o => o.id === obj.id),
      );
      await this.bulkStoreFulltextWithEans(newObjects);
      // Artikel, die bereits in der idb sind
      const updatedObjects = objs.filter(obj =>
        currentObjs.find(o => o.id === obj.id),
      );
      console.log('updatedObjects', updatedObjects);
      console.log('currentObjs', currentObjs);
      this.fulltextService.bulkStore(updatedObjects, 'name');
      for (const obj of updatedObjects) {
        const currentObj = currentObjs.find(o => o.id === obj.id);
        await this.checkChangesToEans(currentObj, obj);
      }
    }
    await super.bulkStore(objs);
  }

  /**
   * Speichert Artikelnamen im fulltextst-Store und EANs im EAN-Store
   * @param obj - der Artikel, der gespeichert werden soll
   */
  private async bulkStoreFulltextWithEans(objs: ArticleModel[]): Promise<void> {
    if (objs.length === 0) return;
    this.fulltextService.bulkStore(objs, 'name');
    const eanPromises: Promise<void>[] = [];
    for (const article of objs) {
      for (const ean of article.eans) {
        eanPromises.push(this.articleEanService.storeEan(ean));
      }
    }
    await Promise.all(eanPromises);
  }

  /**
   * Überprüft, ob sich die EANs geändert haben.
   * Wenn sich die remote_id eines Eans geändert hat, wird der alte Ean gelöscht und der neue gespeichert
   * @param currentObj
   * @param obj
   */
  private async checkChangesToEans(
    currentObj: ArticleModel,
    obj: ArticleModel,
  ) {
    for (const ean of obj.eans) {
      const existingEan = currentObj.eans.find(e => e.id === ean.id);
      if (!existingEan) {
        this.articleEanService.storeEan(ean);
      } else if (ean.remote_id !== existingEan.remote_id) {
        // Ean kann nicht aktualisiert werden, da remote_id der Ean der Schlüssel ist
        await this.articleEanService.deleteEan(existingEan);
        await this.articleEanService.storeEan(ean);
      }
    }
  }

  /**
   * Daten aus dem lokalen Datenbestand löschen
   * @param {number} id
   * @return {Promise<any>}
   */
  public async localDelete(id: number): Promise<any> {
    const currentObj = (await this.localFind(id)).content;
    await super.localDelete(id);
    if (currentObj) {
      this.fulltextService.delete(currentObj, 'name');
      for (const ean of currentObj.eans) {
        this.articleEanService.deleteEan(ean);
      }
    }
  }

  /**
   * Sucht nach Artikeln anhand eines Wertes (name)
   * @param value - der Wert, nach dem gesucht werden soll
   * @returns
   */
  public fulltextSearch(value: string): Observable<ArticleModel[]> {
    return this.fulltextService.search(value, this.type);
  }

  /**
   * loads article with name 'Arbeitseinheit'
   * @returns geet-request to articles/work_articles
   */
  filterForWork(): Observable<any> {
    return this.http
      .get<any>(this.endpointWithUrl + 'work_articles')
      .pipe(retry(1), catchError(this.errorService.convert));
  }
}
