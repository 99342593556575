import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SyncProcessorService } from "@vending/sync-engine-client";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { catchError, Observable, retry } from "rxjs";
import { toFormData } from "src/app/components/mits-files-upload/mits-files-upload-select/helpers/formDataParser";
import { MachineTypeModel } from "src/app/models/machines/machine-type";
import { ImageModel } from "src/app/models/system/image";
import { ErrorService } from "../../error.service";
import { EventsService } from "../../events.service";
import { OfflineDataService } from "./../../offlineData.service";

@Injectable({
  providedIn: "root",
})
export class MachineTypeService extends OfflineDataService<MachineTypeModel> {
  constructor(
    public indexedDBService: NgxIndexedDBService,
    public syncProcessor: SyncProcessorService,
    public http: HttpClient,
    public errorService: ErrorService,
    public events: EventsService
  ) {
    super(
      indexedDBService,
      syncProcessor,
      "Machines::MachineType",
      http,
      errorService,
      events,
      "machines/machine_types/",
      "machine_type",
      ["created_at", "updated_at", "created_by_id"],
      []
    );
  }

  override saveRemote(object: MachineTypeModel): Observable<MachineTypeModel> {
    if (object.id === null) {
      delete object.id;
    }
    // Mappen auf String, damit Datum via FormData versendet werden kann
    object?.machine_type_documents?.map((doc) => {
      if (doc.release_date) {
        doc.release_date = new Date(doc.release_date).toISOString();
      }
    });

    if (object.id >= 0) {
      return this.http
        .put<MachineTypeModel>(
          this.endpointWithUrl + object.id,
          toFormData(object, this, ["file"])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    } else {
      return this.http
        .post<MachineTypeModel>(
          this.endpointWithUrl,
          toFormData(object, this, ["image"])
        )
        .pipe(retry(1), catchError(this.errorService.convert));
    }
  }
}
